import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeRouter from '@Home/router'
import NotFoundPage from '@/pages/NotFoundPage'
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';

function AppRouter() {
    return (
        <BrowserRouter basename={'/'}>
			<Header />
				<Routes>
					{ HomeRouter }
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			<Footer />
        </BrowserRouter>
    );
}

export default AppRouter;
