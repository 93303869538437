import Lottie from "lottie-react"
import AnimationFailure from '@/icons/animations/Failure.json'
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { animationSizes } from '@/constants'
import parse from 'html-react-parser'

export default function ErrorComponent(props) {
	const [, notificationOccurred] = useHapticFeedback();

	const errors = {
		default: {
			title: 'Упс! Что-то пошло не так...',
			text: 'Произошла ошибка, перезапустите приложение.',
			text2: 'Не помогло? Попробуйте обновить приложение Telegram до последней версии.',
		},
		410: {
			title: 'Упс! Что-то пошло не так...',
			text: 'Наш бот не смог отправить вам сообщение.',
			text2: 'Отправьте боту <a href="https://t.me/hlv1bot">@hlv1bot</a> команду /start, чтобы получить ссылки на все сообщества «Халявщики»!',
		}
	}

	const [status, setStatus] = useState('default')

	useState(() => {
		notificationOccurred('error')
		if (props.error && props.error.response && props.error.response.status) {
			if (props.error.response.status === 410) {
				setStatus(410)
			}
		}
	}, [])

	return (
		<main>
			<div className='boxIntro'>
				<div className='boxIntro_sticker'>
					<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
				</div>
				<div className='boxIntro_title'>{errors[status].title}</div>
				<div className='boxIntro_text'>{errors[status].text}</div>
				<div className='boxIntro_text'>{parse(errors[status].text2)}</div>
			</div>
		</main>
    );
};
