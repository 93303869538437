import React, { useState, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import Page from '@/components/Page';
import HomeApi from '../api';
import Load from '@/components/Load';
import useEnvInitData from '@/hooks/useEnvInitData'
import { bgColorsArray } from '@/constants';
import { HandySvg } from 'handy-svg';
import IconArrowDown from '@/icons/arrow-down.svg'
import { MainButton, useHapticFeedback, useWebApp } from '@vkruglikov/react-telegram-web-app';

export default function HomePage() {
	const { showBoundary } = useErrorBoundary()
	const [, InitData] = useEnvInitData();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const WebApp = useWebApp();

	const [loading, setLoading] = useState(true)
	const [boxOpened, setBoxOpened] = useState(false)
	const [channelsFailure, setChannelsFailure] = useState([]);
	const [channelsSuccess, setChannelsSuccess] = useState([]);
	const [successChannelsPhotoCount, setSuccessChannelsPhotoCount] = useState(0);

	useEffect(() => {
		HomeApi.init({
			initData: InitData,
		}).then(function(result) {
			setLoading(false)
			if (result.channels) {
				let success = [];
				let failure = [];
				let tempSuccessChannelsPhotoCount = 0;
				for (let key in result.channels) {
					if (result.not_subscribed.includes(result.channels[key].id)) {
						failure.push(result.channels[key])
					} else {
						success.push(result.channels[key])
						if (result.channels[key].photo) {
							if (tempSuccessChannelsPhotoCount < 5) {
								tempSuccessChannelsPhotoCount++;
							}
						}
					}
				}
				setSuccessChannelsPhotoCount(tempSuccessChannelsPhotoCount)
				setChannelsSuccess(success)
				setChannelsFailure(failure)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			setLoading(false)
			showBoundary(e)
		})
	}, [])

	const [sendToBot, setSendToBot] = useState(false)
	const handleToBot = () => {
		impactOccurred('soft')
		setSendToBot(true)
		HomeApi.toBot({
			initData: InitData,
		}).then(function(result) {
			setSendToBot(false)
			if (result && result[0]) {
				notificationOccurred('success')
				WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT)
				WebApp.close()
			} else {
				showBoundary()
			}
		}).catch((e) => {
			setSendToBot(false)
			showBoundary(e)
		})
	}

	return (
    	<Page title='Главная'>
			{loading ?
				<Load/>
			:
				<>
					<div className='boxList_box-title' style={{marginBottom: 10, paddingLeft: 14}}>Не подписаны</div>
					<div className='boxList'>
						{channelsFailure.map((channel) =>
							<a href={channel.link} className='boxList_item' key={channel.id} target={channel.is_tg ? '_self' : '_blank'} rel='noreferrer'>
								{channel.photo ?
									<div className='boxList_item-icon'>
										<img src={channel.photo} alt='' />
									</div>
								:
									<div className='boxList_item-icon'>
										<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{channel.name.substring(0, 1)}</span>
									</div>
								}
								<div className='boxList_item-content'>
									<div className='boxList_item-title'>{channel.name}</div>
									{channel.is_tg && <div className='boxList_item-hint'>Не подписан</div>}
								</div>
								<div className='boxList_item-right'>
									<div className='btn btn--sm'>{channel.is_tg ? 'Подписаться' : 'Открыть'}</div>
								</div>
							</a>
						)}

						{channelsSuccess.length > 0 &&
							<div className={'boxList_box' + (boxOpened || channelsSuccess.length === 1 ? ' boxList_box--opened' : '')}>
								<button className='boxList_item boxList_box-open' onClick={() => setBoxOpened(true)}>
									{successChannelsPhotoCount > 0 &&
										<div className={'boxList_item-icons boxList_item-icons--' + successChannelsPhotoCount}>
											{channelsSuccess.slice(0, 5).map((channel) => {
												return channel.photo ? <img src={channel.photo} key={channel.id} alt='' /> : null;
											})}
										</div>
									}
									<div className='boxList_item-content'>
										<div className='boxList_item-title'>Посмотреть все</div>
									</div>
								</button>

								<div className='boxList_box-header'>
									<div className='boxList_box-title'>Вы уже подписаны</div>
									{channelsSuccess.length !== 1 && boxOpened &&
										<button className='boxList_box-close' onClick={() => setBoxOpened(false)}>
											<HandySvg src={IconArrowDown} />
											Свернуть
										</button>
									}
								</div>

								<div className='boxList_box-body'>
									{channelsSuccess.map((channel) =>
										<a href={channel.link} className='boxList_item' key={channel.id}>
											{channel.photo ?
												<div className='boxList_item-icon'>
													<img src={channel.photo} alt='' />
												</div>
											:
												<div className='boxList_item-icon'>
													<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{channel.name.substring(0, 1)}</span>
												</div>
											}
											<div className='boxList_item-content'>
												<div className='boxList_item-title'>{channel.name}</div>
												<div className='boxList_item-hint'>Подписан</div>
											</div>
										</a>
									)}
								</div>
							</div>
						}
					</div>
					<MainButton
						text={sendToBot ? 'Отправляем...' : 'Отправить в Телеграм'}
						onClick={handleToBot}
						progress={sendToBot}
						disabled={sendToBot}
					/>
				</>
			}
	    </Page>
  	);
}
