import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { testInitData, testInitDataUnsafe } from '@/constants'

export default function useEnvInitData() {
	const [InitDataUnsafe, InitData] = useInitData();

	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		return [testInitDataUnsafe, testInitData]
	} else {
		return [InitDataUnsafe, InitData]
	}
}
