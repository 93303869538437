import Lottie from 'lottie-react';
import AnimationSearch from '@/icons/animations/Search.json'
import { animationSizes } from '@/constants'

export default function Load() {
	return (
		<div className='boxIntro'>
			<div className='boxIntro_sticker'>
				<Lottie animationData={AnimationSearch} style={animationSizes['AnimationSearch']} />
			</div>
			<div className='boxIntro_title'>Загружаем данные...</div>
		</div>
	)
}
